export default {
    computed: {
        attrs() {
            return {
                id: this.id || this.$attrs.id,
                name: this.$attrs.name,
                sensitive: this.$attrs.sensitive,
                viewBox: this.viewBox
            }
        }
    }
}
